<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">浙江省禁毒总队、福建省禁毒总队领导一行莅临历思就“污水验毒”等技术进行考察交流！</div>
      <p class="time">2020.12.03</p>

      <p class="space_content" style="text-indent:0">
        近日，浙江省禁毒总队、福建省禁毒总队领导一行 莅临福建历思司法鉴定所（以下简称“历思”）考察交流城市污水监测、毒情监测预警体系建设等工作。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/36-1.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/36-2.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/36-3.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        浙江省禁毒总队、福建省禁毒总队领导一行参观了历思物证中心实验室， 对 “单根毛发验毒” 和 “实时在线污水采集” 技术的新设备、新平台产生了浓厚 的兴趣，并观看了 “智慧禁毒物联网” 以及由禁毒办指导研发的 “国产新型质谱毒检仪” 、 “智慧水质采样器 的演示，高度评价了历思在禁毒装备创新和禁毒科技应用等方面取得的突出成果。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/36-4.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/36-5.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/36-6.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/36-7.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        在座谈会上，双方就 城市污水监测、毒情监测预警体系建设 以及 毛发毒检 等方面进行深入的沟通交流。浙江省禁毒总队、福建省禁毒总队领导一行表示， 禁毒工作离不开科技助力，历思的科技创新成果应积极推广，更好地应用于禁毒一线，与禁毒部门形成合力，共同打击各类毒品犯罪活动。
      </p>



    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/35'"><p class="ellipsis1">全国首例！历思助力技术调查官参审污染环境案件，宣判啦~</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/37'"><p class="ellipsis1">厦门市税务局稽查局办公室和检查三科党支部与历思共建学习交流会！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
